import React, { useState, useEffect, useRef, useContext } from 'react';
import Footer from "../../components/Account/Footer";
import HeaderImage from "../../components/Account/HeaderImage";
import { useForm } from 'react-hook-form';
import { $CommonServiceFn } from '../../components/network/Service';
import { $Service_Url, ipUrl } from '../../components/network/UrlPath';
import Captcha from '../../components/Account/reCaptcha/Captcha';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import konsole from '../../components/control/Konsole';
import commonLib from '../../components/control/commonLib';
import { UserContext } from '../../App';
import config from '../../config.json'
import PhoneNumberMask from '../../components/Account/PhoneNumberMask';
import NotificationPermission from '../../components/NotificationPermission'
// import "./Univer.css"
import { capitalizeAllLetters, capitalizeFirstLetterFirstWord } from '../../components/Reusable/ReusableCom';
import { errorMsg, successMsg } from '../../components/control/Constant';
import CommonInputContainer from '../../components/CommonInputContainer';

const SignUp = (props) => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState('password');
  const [countryCode, setcountryCode] = useState([]);
  const [emailRegistered, setEmailRegistered] = useState(false);
  // const [CountryGeoId, setCountryGeoId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalSecond, setShowModalSecond] = useState(false);
  const captchaRef = useRef(null);
  const notificationRef = useRef(null)
  const { setLoader, setcurMainImageNum, setWarning } = useContext(UserContext);

  const [passworderr, setpassworderr] = useState('')
  const [password, setPassword] = useState('')
  const [conformpassword, setconformpassword] = useState('')
  const [showModalthird, setshowModalthird] = useState(false)
  const [msgthirdmodal, setmsgthirdmodal] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [showHearAboutUsDropdown, setShowHearAboutUsDropdown] = useState(false)
  const [showPhoneError, setshowPhoneError] = useState(false);
  const [passCritErr, setpassCritErr] = useState({})
  const [selectedHearAbout,setSelectedHearAbout] = useState([])
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [focusedOption, setFocusedOption] = useState(null);


  const [selectOptionhearaboutlist,setselectOptionhearaboutlist] = useState([])
  const [otherInput,setOtherInput] = useState("") 
  let subtenantId = commonLib.getQueryFromUrl("subtenantId");
  const startingTabIndex = props?.startTabIndex ?? 0;
  let inputData = {}
  let phoneRef = useRef();
  const showFreetrail = (JSON.parse(commonLib.getSessionStorage("subtenantDetails"))?.subtenantId === 3 || subtenantId == 3) ? true : false
  const dropdownRef = useRef(null);
  const toggleRef = useRef(null);



  useEffect(() => {
    getCountryId();
    // getCountryIpCode()
    setcurMainImageNum(2);
    getSubjectdataApi()
  }, [])


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current && 
      !dropdownRef.current.contains(event.target) &&
      toggleRef.current && 
      !toggleRef.current.contains(event.target)
    ) {
      setShowHearAboutUsDropdown(false);
      setFocusedOption(null);
    }
  };

  // const handleChange = (event) => {
  //   // setSignUp({[event.target.id]: event.target.value});
  // }

  // const handleSignup = () => {
  // }

  const getCountryId = () => {
    // setLoader(true);
    $CommonServiceFn.InvokeCommonApi("GET", $Service_Url.getCountryCode, "", (response) => {
      if (response) {
        // setLoader(false);
        setcountryCode(response.data.data)
        konsole.log("sgfgasfgasa", response.data.data[0]?.value)
        setValue("countryCode", response.data.data[0]?.value)
      }
    })
  }

  // const getCountryIpCode = async () => {
  //   setLoader(true);
  //   let parsedData;
  //   await fetch(ipUrl)
  //   .then((data) => parsedData = data.json())
  //   .catch((err) => konsole.log("getCountryIpCode-err", err));
  //   konsole.log("getCountryIpCode-dattaa", parsedData);
  //   setLoader(false);
  //   if (parsedData?.country_code == 'IN') {
  //     setCountryGeoId('IN')
  //   }
  //   else if (parsedData?.country_code == 'US' || parsedData?.countryCode == undefined) {
  //     setCountryGeoId('US')
  //   }
  // }
  const { register, setError, handleSubmit, watch, setValue, clearErrors, formState: { errors }, setFocus } = useForm();


 const getSubjectdataApi = () => {
  let url = `${$Service_Url.getSubjectbyquestionId}213`
  $CommonServiceFn.InvokeCommonApi("GET",url,"",(response,error)=>{
    if(response){
      konsole.log(response,"getSubjectbyquestionIdresponse")
      let responseData = response.data.data
      setselectOptionhearaboutlist(responseData)
      
    }else{
      konsole.log(error,"error")
    }
  })
 }

  const isValidate = ( formData ) => {
    // setWarning({title: "Helpful warning Message", description: "Description text goes here. To be replaced by the information suitable for the specific context."});
    let isAnyError = false;
    let errorCount = 0;
    const {confirmPassword, countryCode, emailId, firstName, hearAbout, lastName, mobileNumber, password, phoneNo} = formData;

    if(!firstName) {
      setError("firstName", { type: "error", message: errorMsg.firstName.empty })
      if(!isAnyError) setFocus("firstName")
      isAnyError = true
      errorCount++;
    } else if(/[A-Za-z]/.test(firstName) == false) {
      setError("firstName", { type: "error", message: errorMsg.firstName.invalid })
      if(!isAnyError) setFocus("firstName")
      isAnyError = true
      errorCount++;
    }

    if(!lastName) {
      setError("lastName", { type: "error", message: errorMsg.lastName.empty })
      if(!isAnyError) setFocus("lastName")
      isAnyError = true
      errorCount++;
    } else if(/[A-Za-z]/.test(lastName) == false) {
      setError("lastName", { type: "error", message: errorMsg.lastName.invalid })
      if(!isAnyError) setFocus("lastName")
      isAnyError = true
      errorCount++;
    }

    konsole.log("dnvjksbv", phoneRef)
      
    if (phoneNo == undefined || phoneNo == "") {
      setError("phoneNo", { type: "error", message: errorMsg.phoneNumber.empty })
      setshowPhoneError(false)
      if(!isAnyError) phoneRef?.current?.focus()
      isAnyError = true
      errorCount++;
    } else if (phoneNo?.length < 10) {
      setError("phoneNo", { type: "error", message: errorMsg.phoneNumber.invalid })
      setshowPhoneError(false)
      if(!isAnyError) phoneRef?.current?.focus()
      isAnyError = true
      errorCount++;
    }

    if(!emailId) {
      setError("emailId", { type: "error", message: errorMsg.emailId.empty })
      if(!isAnyError) setFocus("emailId")
      isAnyError = true
      errorCount++;
    } else if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailId) == false) {
      setError("emailId", { type: "error", message: errorMsg.emailId.invalid })
      if(!isAnyError) setFocus("emailId")
      isAnyError = true
      errorCount++;
    }

    if(!password) {
      setError("password", { type: "error", message: errorMsg.password.empty })
      if(!isAnyError) setFocus("password")
      isAnyError = true
      errorCount++;
    } else if(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*=_+\-<>{}[\]():";?',.\\\/|~`])[A-Za-z\d!@#$%^&*=_+\-<>{}[\]():";?',.\\\/|~`]{8,}$/.test(password) == false) {
      setError("password", { type: "error", message: errorMsg.password.invalid })
      if(!isAnyError) setFocus("password")
      isAnyError = true
      errorCount++;
    }

    if(!confirmPassword) {
      setError("confirmPassword", { type: "error", message: errorMsg.confirmPassword.empty })
      if(!isAnyError) setFocus("confirmPassword")
      isAnyError = true
      errorCount++;
    } else if(password != confirmPassword) {
      setError("confirmPassword", { type: "error", message: errorMsg.confirmPassword.invalid })
      if(!isAnyError) setFocus("confirmPassword")
      isAnyError = true
      errorCount++;
    }

    if(errorCount == 6) setWarning('warning', 'Please fill out all required fields as highlighted.')

    konsole.log("dsbvjks", isAnyError)
    return isAnyError == false;
  }

  const onSubmit = ( data ) => {
    konsole.log(selectedHearAbout,"userSubjectList2")

    const phoneNo = watch("phoneNo");
    console.table("sdvjbsdkj", data, phoneNo);
    // konsole.log("watch list",isValidate(data), watch("phoneNo"), typeof (phoneNo));

    if(isValidate(data) != true) return;

    inputData = data;

    inputData["mobileNumber"] = inputData.countryCode + inputData.phoneNo;


    const subtenantId = JSON.parse(commonLib.getSessionStorage("subtenantDetails"))?.subtenantId || 2;
    const input = {
      subtenantId: subtenantId,
      signUpPlateform: 1,
      user: {
        roleId:showFreetrail ? 18 : 1,
        firstName: capitalizeFirstLetterFirstWord(inputData.firstName),
        lastName: capitalizeFirstLetterFirstWord(inputData.lastName),
        emailAddress: inputData.emailId,
        userName: inputData.emailId,
        countryCode: inputData.countryCode,
        mobileNumber: inputData.phoneNo,
        password: inputData.password,
        packageCode: showFreetrail ? inputData?.promoCode : null,
      }
    }


    let otpSendMedium;
    if (input.user.mobileNumber !== null && input.user.emailAddress) {
      otpSendMedium = config.OTPMEDIUMBOTH
    }
    else if (input.user.mobileNumber !== null) {
      otpSendMedium = config.OTPMEDIUMSMS
    }
    else if (input.emailAddress !== null) {
      otpSendMedium = config.OTPMEDIUMEMAIL;
    }

    konsole.log("otpSendMedium", otpSendMedium)


    konsole.log("input", input)

    // konsole.log("inputinput",input)
    // const postData = {
    //   subtenantId: commonLib.getSessionStorage("subtenantId") || 2,
    //   user: input
    // }
    konsole.log("inputinput", JSON.stringify(input))
    if (input.user.firstName !== undefined && input.user.lastName !== undefined && input.user.emailAddress !== undefined && input.user.mobileNumber !== undefined && input.user.password !== undefined && input.user.packageCode !== undefined) {
      // let captchaValue = captchaRef.current.checkCaptcha();
      // if (captchaValue == null) {
      //   konsole.log("catcha null");
      //   return;
      // }

      setLoader(true);
      let url = showFreetrail ? $Service_Url.createAcademyUser : $Service_Url.postsignupcreateUser
      $CommonServiceFn.InvokeCommonApi("POST",url, input, async (response, errorData) => {
        setLoader(false);
        if (response) {
          konsole.log("postSign", response);
          // const _resultNotification = await notificationRef.current.saveCheckBoxInfo(response?.data?.data?.userId);
          // konsole.log("_resultNotification", _resultNotification);
          // debugger;
          submitHearaboutus(response.data.data.userId)
          if (response?.data?.data?.userActivationMode == "ROLE_MAPPED") {
            // setShowModalSecond(true);
            if (subtenantId == 3) {
              const { emailAddress, mobileNumber, firstName, lastName, packageCode, countryCode } = input.user

              const promoCodeVal=(packageCode==undefined || packageCode ==null || packageCode=='') ? 'free academy user':packageCode
              let JsonObj = {
                  email: emailAddress,
                  phone: countryCode +  mobileNumber,
                  firstName: firstName,
                  lastName: lastName,
                  name: firstName + ' ' + lastName,
                  // address1: '',
                  // city: '',
                  // state: '',
                  // country: '',
                  // postalCode: '',
                  // companyName: '',
                  // website: '',
                  // tags: ['Academy User', packageCode],
                  tags: [promoCodeVal],
                  // source: '',
                  // customField: { "_custom_field_id_": '' }
              };

              const rResposne = await signUpLeadStackApi(JsonObj);
              konsole.log("rResponseShow", rResposne)
              navigate("/account/congratulation");
          }
            setEmailError(errorMsg.emailId.already)
          }
          else {
            sendOtpApi(subtenantId, input.signUpPlateform, response.data.data.userId, otpSendMedium, response.data.data.id)
          }
        } else if (errorData) {
          konsole.log("dzbjks", errorData)
          if (errorData?.data?.data?.userActivationMode == "ACTIVATE_USER") {
            // setShowModal(true);
            setEmailError(errorMsg.emailId.already)
          } else if (errorData?.data?.errorFlag == "INVALID_SUBTENANT") {
            // setshowModalthird(true)
            // setmsgthirdmodal(errorData?.data.messages[0])
            setEmailError(errorData?.data.messages[0])
          } else if (errorData?.data?.succeeded == false) {
            // setEmailRegistered(true);
            setEmailError(errorMsg.emailId.already)
            konsole.log("errorData", errorData)
            // captchaRef.current.refreshCaptcha();
            konsole.log('email', emailRegistered);
          } else {
            setWarning('warning', errorMsg.somethingErr);
          }
        }
      });
    }
  }

  const setEmailError = ( error ) => {
    setError("emailId", { type: "error", message: error });
    setFocus("emailId");
    // setWarning('warning', error);
  }


  const signUpLeadStackApi = (jsonObj) => {
    return new Promise((resolve, reject) => {
      $CommonServiceFn.InvokeCommonApiForLeadStack(jsonObj, (res, err) => {
        if (res) {
          konsole.log('InvokeCommonApiForLeadStack', res)
          konsole.log('InvokeCommonApiForLeadStack', res)
          resolve('resolve')
        } else {
          resolve('reject')
          konsole.log('InvokeCommonApiForLeadStack', err)
          konsole.log('InvokeCommonApiForLeadStack', err)

        }
      })
    })
  }

  const submitHearaboutus = (userId) =>{

    let userSubjectList = selectedHearAbout.map((item)=>{
      return {userSubjectDataId:0,subjectId:item.subjectId,subResponseData:item.response,responseId:item.responseId}
    })
    if(userSubjectList.some((e)=> e.responseId == 418)){
      let otherInputjson = {responseId:"419",subResponseData:otherInput,subjectId:"217",userSubjectDataId:0}
      userSubjectList.push(otherInputjson)
    }
    // let jsonObj = {
    //   "userId":userId,
    //   "userSubjects":userSubjectList
    // }
    let jsonObj = userSubjectList?.map(ele => ({userId: String(userId), responseId: String(ele.responseId), 
    subjectId: String(ele.subjectId), userSubjectDataId: String(ele.userSubjectDataId), subResponseData: String(ele.subResponseData),}));
    konsole.log(selectedHearAbout,"userSubjectList")

    $CommonServiceFn.InvokeCommonApi("POST",$Service_Url.postaddusersubjectdata,jsonObj,(response,error)=>{
      if(response){
        konsole.log(response,"response")
      }else{
        konsole.log(error,"error")
      }
    })
  }

  const sendOtpApi = (subtenantId, signUpPlateform, userId, otpSendMedium, userRegisteredId) => {
    konsole.log("sendOtpApi")
    const postData = {
      "createdBy": userId,
      "otpSendMedium": otpSendMedium,
      "subtenantId": subtenantId,
      "otpType": config.USERACTIVATION
    }

    konsole.log("postdata", postData)

    $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.postSendOtp, postData, (resp, error) => {
      if (resp) {
        konsole.log("otp send resp", resp);
        let createId = userId;
        let Id = resp.data.data.id;
        let otpSendMedium = resp.data.data.otpSendMedium;
        let otpSendOn = resp.data.data.otpSendOn;
        let senddata = inputData;
        senddata['phoneNo'] = inputData?.mobileNumber

        konsole.log('idd', Id, inputData);
        setWarning('success', successMsg.otpSent);
        sessionStorage.setItem('remainingTime', '');
        commonLib.resetSS4Otp();
        navigate(`/Account/verifyOtp?subtenantId=${subtenantId}`, {
          state: {
            createId: createId,
            Id: Id,
            userRegisteredId: userRegisteredId,
            otpSendOn: otpSendOn,
            otpSendMedium: otpSendMedium,
            subtenantId: subtenantId,
            senddata: senddata,
            signUpPlateform: parseInt(signUpPlateform),
            typeOfPage: 'signup'
          }
        })
      }
      else if (error) {
        konsole.log("error", error)
        setWarning('warning', errorMsg.somethingErr);
      }
    })
  }


  const handleEmail = (e) => {
    inputfieldcapital(e)
    // setEmailRegistered(false);
    // setShowModal(false);
  }


  const passwordToggle = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
    }
    else {
      setPasswordType('password')
    }
  }

  konsole.log('resp', countryCode)
  // konsole.log(errors);
  // konsole.log("id",CountryGeoId);



  const onChangepassword = (e) => {
    let pass_id = e.target.id;
    let pass_value = e.target.value
    if (pass_id == 'password') {
      setPassword(pass_value)
    }
    if (pass_id == "cnf-password") {
      setconformpassword(pass_value)
    }

    // if(password == conformpassword){
    //   setpassworderr(false)
    // }else {
    //   setpassworderr(true)
    // }
    // konsole.log("eeeee",pass_id)
    // konsole.log("passwordpassword",conformpassword,password)
  }

  useEffect(() => {
    if (password !== '' && password !== null && password !== undefined && conformpassword !== null && conformpassword !== undefined && conformpassword !== '') {
      if (password == conformpassword) {
        setpassworderr(false)
      } else {
        setpassworderr(true)
      }

    }


  }, [password, conformpassword])



  const inputfieldcapital = (val) => {
    const valueenter = val.target.value?.replace(/^\s+/g, '');
    // const valueid = val.target.id;
    const valueName = val.target.name
    let value = valueenter;
  
    if (valueName == 'firstName' || valueName == 'lastName') {
      value = capitalizeFirstLetterFirstWord(valueenter);
    } 
    konsole.log(val.target.value, val.target.id)

    if (valueName == 'firstName') {
      setFirstName(value)
    } else if (valueName == 'lastName') {
      setlastName(value)
    }

    val.target.value = value;
  }

  // konsole.log("lastNamelastName")
  // if (JSON.parse(commonLib.getSessionStorage("subtenantDetails"))?.subtenantId === 3 || subtenantId == 3) {
  //   const sub = subtenantId || JSON.parse(commonLib.getSessionStorage("subtenantDetails"))?.subtenantId
  //   return navigate(`/account/startfreetrial?subtenantId=${sub}`)
  // }


  const captchaValidate = () => {
    let captchaValue = captchaRef.current.checkCaptcha();

  }

  const ErrorShower = ({ inputName }) => {
    return errors?.[inputName]?.message ? <p className='attention'>{errors?.[inputName]?.message}</p> : "";
  }

  const selectHearaboutus = (newValue) => {
    konsole.log(newValue, "valuevaluevaluevalue");
  
    setSelectedHearAbout((selectedHearAbout) => {
      if(selectedHearAbout.some((e)=>e.responseId == newValue.responseId)){
        const filteredArray = selectedHearAbout.filter(item => item.responseId != newValue.responseId);
        return [...filteredArray];
      }else{
      const filteredArray = selectedHearAbout.filter(item => item.responseId != newValue.responseId);
      return [...filteredArray, newValue];
      }
    });
  };

  const handleInput =(e)=>{
    e.target.value = e.target.value.trimStart();
  }
  konsole.log(errors,"errorserrorserrorserrors")

  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      if (showHearAboutUsDropdown && focusedOption) {
        const selectedOption = selectOptionhearaboutlist?.find(
          (item) => item?.responseId == focusedOption
        );
        if (selectedOption) {
          selectHearaboutus(selectedOption);
        }
      } else {
        setShowHearAboutUsDropdown(!showHearAboutUsDropdown);
      }
      return;
    }
  
    if (showHearAboutUsDropdown) {
      let currentIndex = selectOptionhearaboutlist?.findIndex(
        (item) => item?.responseId === focusedOption
      );
  
      if (e.key == "ArrowDown") {
        e.preventDefault();
        const nextIndex = (currentIndex + 1) % selectOptionhearaboutlist?.length;
        setFocusedOption(selectOptionhearaboutlist[nextIndex]?.responseId);
      } else if (e.key == "ArrowUp") {

        e.preventDefault();
        const prevIndex =
          (currentIndex - 1 + selectOptionhearaboutlist?.length) %
          selectOptionhearaboutlist?.length;
        setFocusedOption(selectOptionhearaboutlist[prevIndex]?.responseId);
      }
    }
  };
  
  const handleSelectEnter = (event) => {
    if (event.key == "Enter") {
        setMenuIsOpen(true);
    }
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false); 
  };

  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      clearErrors()
      // konsole.log("dsvkjbjk", errors)
      handleSubmit(onSubmit)()
    }} id='signUp'>
      <div className='headerImg'>
        <HeaderImage />
      </div>
      <div className="vertically-center8">
      <div className='parentDiv'>
      <p className='title'>Sign up</p>
      {showFreetrail && <p className='sub-title'>Start your 30-day free trial.</p>}
      <div className='singnUpScroll'>
        <CommonInputContainer
          classNameProps={"mt-1"}
          label="First Name*"
          error={errors?.firstName}
        >
          <input tabIndex={ startingTabIndex + 1} className={`upperCasing w-100 ${errors.firstName ? 'input-error' : ''}`} type='text' placeholder='Enter your first name'
            {...register("firstName", {
              onChange: (e) => { inputfieldcapital(e) }
            })} 
          />
        </CommonInputContainer>

        <CommonInputContainer
          label="Last Name*"
          error={errors?.lastName}
        >
          <input tabIndex={ startingTabIndex + 2}  className={`upperCasing w-100 ${errors.lastName ? 'input-error' : ''}`} type='text' placeholder='Enter your last name'
            {...register("lastName", {
              onChange: (e) => { inputfieldcapital(e) }
            })}
          />
        </CommonInputContainer>

        <div className=''>
          <label className='phoneNumber'>Phone Number*</label>
          <div className={`phone-number-input position-relative ${errors?.phoneNo ? 'input-error' : ''} `}>
            <div className="country-code">
              <select
                {...register("countryCode", {
                  onChange: (e) => { inputfieldcapital(e) }
                })}
                tabIndex={startingTabIndex + 3}
                menuIsOpen={menuIsOpen} 
                onMenuClose={handleMenuClose}
                onKeyDown={handleSelectEnter}
              >
                {/* <option value="+1">US</option>
                <option value="+91">IN</option> */}
                {commonLib.SortCountryfromArray(countryCode)?.map((item, index) => {return (<option key={index} value={item.value}>{item.label}</option>)})}
              </select>
            </div>
            <PhoneNumberMask startTabIndex={startingTabIndex + 4}  setValue={setValue} setError={setError} setClearError={clearErrors} setPhoneRef={(reff) => phoneRef.current = reff.current}/>
            {(errors?.phoneNo?.message) ? <img 
              src='/icons/validationImg.svg' 
              className='validation-icon img-fluid cursor-pointer mx-1'  
              alt='' 
              style={{top: 'inherit'}}
              // onMouseOver={()=>setshowPhoneError(!showPhoneError)}
              // onMouseOut={()=>setshowPhoneError(!showPhoneError)}
            /> : ""}
          </div>
          {(errors?.phoneNo?.message) && <ErrorShower inputName={"phoneNo"}/>}
          {/* {(errors?.phoneNo?.message && showPhoneError) && <ErrorShower inputName={"phoneNo"}/>} */}
        </div>

        <CommonInputContainer
          label="Email*"
          error={errors?.emailId}
        >
          <input tabIndex={ startingTabIndex + 5} type='text' className={`w-100 ${errors.emailId ? 'input-error' : ''}`} placeholder='i.e johnoe@gmail.com' onInput={handleInput}
            {...register("emailId", {
              onChange: (e) => {  handleEmail(e) }
            })}
          />
        </CommonInputContainer>

        <CommonInputContainer
          label="Password*"
          error={errors?.password}
        >
          <input tabIndex={startingTabIndex +  6} style={{paddingRight:"50px"}} className={`w-100 ${errors.password ? 'input-error' : ''}`} type={passwordType} placeholder='Enter password'
            {...register('password', {
              onChange: (e) => { e.target.value = e.target.value.trimStart(); commonLib.checkPasswordCriteria(e.target.value, setpassCritErr); }})}
          />
          <img src={(passwordType === 'password') ? '/icons/eye-off.svg' : '/icons/eye-show.svg'}
            className={`toggle-icon img-fluid cursor-pointer ${errors?.password ? 'error' : ''}`} onClick={passwordToggle} alt='toggle visibility'
          />
        </CommonInputContainer>
        
        <CommonInputContainer
          label="Confirm Password*"
          error={errors?.confirmPassword}
        >
          <input tabIndex={startingTabIndex +  7} style={{paddingRight:"50px"}} type={passwordType} placeholder='Enter password again'
            className={`w-100 ${errors.confirmPassword ? 'input-error' : ''}`}
            {...register("confirmPassword", {
              onChange: (e) => { inputfieldcapital(e) }
          })}/>
        <img src={(passwordType === 'password') ? '/icons/eye-off.svg' : '/icons/eye-show.svg'}
          className={`toggle-icon img-fluid cursor-pointer ${errors?.confirmPassword ? 'error' : ''}`} onClick={passwordToggle} alt='toggle visibility'/>
        </CommonInputContainer>
        <div className='singUp-check'>
            {passCritErr?.islen8 ? <img src='/icons/greenCheck.svg' /> : <img src='/icons/greyCheck.svg' />}
            <p className='mb-0'>Must be at least 8 characters</p>
        </div>
        <div className='singUp-check'>
            {passCritErr?.isUpperChar ? <img src='/icons/greenCheck.svg' /> : <img src='/icons/greyCheck.svg' />}
            <p className='mb-0'>Must contain at least one uppercase letter</p>
        </div>
        <div className='singUp-check'>
            {passCritErr?.isalphaNum ? <img src='/icons/greenCheck.svg' /> : <img src='/icons/greyCheck.svg' />}
            <p className='mb-0'>Must contain alphanumeric characters</p>
        </div>
        <div className='singUp-check'>
            {passCritErr?.isSpecialChar ? <img src='/icons/greenCheck.svg' /> : <img src='/icons/greyCheck.svg' />}
            <p className='mb-0'>Must contain one special character</p>
        </div>
        <div className='label-input'>
          <label>How did you hear about us?</label>
          <div className='row selectBorderHearAboutUs justify-content-between m-0' tabIndex={startingTabIndex + 8}
           onClick={()=>{setShowHearAboutUsDropdown(!showHearAboutUsDropdown)}} onKeyDown={handleKeyDown} ref={toggleRef}>
            <div className='col-10 p-0 hearAboutUsLeftCol'>
            <span className={selectedHearAbout.length > 0 ? 'selected-item' : ''}>{selectedHearAbout.length > 0 ? selectedHearAbout.map((e, index) => e.response).join(", ") : 'Select'}</span>
            </div>
            <div className='col-1 d-flex justify-content-center hearAboutUsRightCol'>
              <img src="/icons/hearAboutOption.svg"/>
            </div>
         </div>

          {showHearAboutUsDropdown && 
                  <div className='d-flex justify-content-end mt-3' ref={dropdownRef}>
                  {/* <div className='d-flex justify-content-end mt-3'> */}
                      <div className='hearAboutus p-2 pt-2'>
                      {selectOptionhearaboutlist?.length > 0 && selectOptionhearaboutlist?.sort((a, b) => (a.response == "Other" ? -1 : b.response == "Other" ? 1 : 0))?.map((item) => {
                      const checkBoxValue = selectedHearAbout?.some((e)=>e.responseId == item?.responseId );
                      return (
                      <div key={item.responseId}>
                       <div className={`options ${focusedOption == item.responseId ? 'focused' : ''}`} onClick={() => selectHearaboutus(item)} >
                       <label className="d-flex gap-2">
                         <input type='checkbox' className='custom-select hearAboutusCustomInput mt-0' checked={checkBoxValue}/>
                          <h3 className={`labelFontSizeForhearAbotus ${item?.response == "Other" ? "otherValueName" : ""}`} onClick={() => selectHearaboutus(item)} >{item?.response}</h3>
                        </label>
                            </div>
                        {selectedHearAbout?.some((e)=>e.responseId == 418) && item.responseId == 418 && <input tabIndex={startingTabIndex + 9} className="w-100" placeholder='Description' value={otherInput}  onChange={(e)=>setOtherInput(e.target.value)} />}     
                  </div>)})}
            </div>
          </div>} 
        </div>
        {showFreetrail && <div className='label-input'>
          <label> Please enter your promo code</label>
          <input tabIndex={startingTabIndex + 10} className='upperCase' type='text' placeholder='Promo Code' {...register('promoCode', {
              onChange: (e) => { inputfieldcapital(e) }
          })}/>
        </div>}
      </div>
      <div className='label-input'>
        <button className='singUpBtn border-0' tabIndex={startingTabIndex + 11} type="submit">Sign Up</button>
      </div>
      <div className='other-links'>
        <p>Already have an account? <NavLink tabIndex={startingTabIndex + 12} className="aTagElement" to='/Account/SignIn'>Log in</NavLink>
        </p>

     </div>
     </div>
      </div>

    </form>
  );
};

export default SignUp;